.flipping-box {
  perspective: 1000px;
  background-color: rgba(0, 0, 0, 0);
}

.flipping-box-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  position: relative;
}

.flipping-box:hover .flipping-box-inner {
  transform: rotateY(180deg);
}

.flipping-box-front, .flipping-box-back {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.flipping-box-back {
  transform: rotateY(180deg);
}

.lds-grid {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-grid div {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  animation: lds-grid 1.2s linear infinite;
  position: absolute;
}

.lds-grid div:nth-child(1) {
  animation-delay: 0s;
  top: 8px;
  left: 8px;
}

.lds-grid div:nth-child(2) {
  animation-delay: -.4s;
  top: 8px;
  left: 32px;
}

.lds-grid div:nth-child(3) {
  animation-delay: -.8s;
  top: 8px;
  left: 56px;
}

.lds-grid div:nth-child(4) {
  animation-delay: -.4s;
  top: 32px;
  left: 8px;
}

.lds-grid div:nth-child(5) {
  animation-delay: -.8s;
  top: 32px;
  left: 32px;
}

.lds-grid div:nth-child(6) {
  animation-delay: -1.2s;
  top: 32px;
  left: 56px;
}

.lds-grid div:nth-child(7) {
  animation-delay: -.8s;
  top: 56px;
  left: 8px;
}

.lds-grid div:nth-child(8) {
  animation-delay: -1.2s;
  top: 56px;
  left: 32px;
}

.lds-grid div:nth-child(9) {
  animation-delay: -1.6s;
  top: 56px;
  left: 56px;
}

@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.lds-ellipsis {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ellipsis div {
  width: 13px;
  height: 13px;
  background: #888;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 33px;
}

.lds-ellipsis div:nth-child(1) {
  animation: lds-ellipsis1 .6s infinite;
  left: 8px;
}

.lds-ellipsis div:nth-child(2) {
  animation: lds-ellipsis2 .6s infinite;
  left: 8px;
}

.lds-ellipsis div:nth-child(3) {
  animation: lds-ellipsis2 .6s infinite;
  left: 32px;
}

.lds-ellipsis div:nth-child(4) {
  animation: lds-ellipsis3 .6s infinite;
  left: 56px;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

.search-result {
  font-weight: 300;
}

.search-result .hl {
  color: var(--chakra-colors-gray-800);
  background: rgba(255, 215, 66, .9);
}

::selection {
  color: #000;
  background: #25daad;
}

.dropzone {
  color: #bdbdbd;
  background-color: #fafafa;
  border: 2px dashed #eee;
  border-radius: 2px;
  outline: none;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 31px;
  transition: border .24s ease-in-out;
  display: flex;
}

.ReactCrop {
  cursor: crosshair;
  max-width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ReactCrop *, .ReactCrop :before, .ReactCrop :after {
  box-sizing: border-box;
}

.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}

.ReactCrop__child-wrapper {
  max-height: inherit;
}

.ReactCrop__child-wrapper > img, .ReactCrop__child-wrapper > video {
  max-width: 100%;
  max-height: inherit;
  touch-action: none;
  display: block;
}

.ReactCrop__crop-selection {
  cursor: move;
  touch-action: none;
  border: 1px dashed #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, .5);
}

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}

.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #fff, 0 0 0 9999em rgba(0, 0, 0, .5);
}

.ReactCrop__crop-selection:focus {
  border-style: solid;
  border-color: #00f;
  outline: none;
}

.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}

.ReactCrop__rule-of-thirds-vt:before, .ReactCrop__rule-of-thirds-vt:after, .ReactCrop__rule-of-thirds-hz:before, .ReactCrop__rule-of-thirds-hz:after {
  content: "";
  background-color: rgba(255, 255, 255, .4);
  display: block;
  position: absolute;
}

.ReactCrop__rule-of-thirds-vt:before, .ReactCrop__rule-of-thirds-vt:after {
  width: 1px;
  height: 100%;
}

.ReactCrop__rule-of-thirds-vt:before {
  left: 33.3333%;
}

.ReactCrop__rule-of-thirds-vt:after {
  left: 66.6667%;
}

.ReactCrop__rule-of-thirds-hz:before, .ReactCrop__rule-of-thirds-hz:after {
  width: 100%;
  height: 1px;
}

.ReactCrop__rule-of-thirds-hz:before {
  top: 33.3333%;
}

.ReactCrop__rule-of-thirds-hz:after {
  top: 66.6667%;
}

.ReactCrop__drag-handle {
  position: absolute;
}

.ReactCrop__drag-handle:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, .2);
  border: 1px solid rgba(255, 255, 255, .7);
  outline: 1px solid rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
}

.ReactCrop__drag-handle:focus:after {
  background: #2dbfff;
  border-color: #00f;
}

.ReactCrop .ord-nw {
  cursor: nw-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 0;
  left: 0;
}

.ReactCrop .ord-nw:after {
  top: 0;
  left: 0;
}

.ReactCrop .ord-n {
  cursor: n-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.ReactCrop .ord-n:after {
  top: 0;
}

.ReactCrop .ord-ne {
  cursor: ne-resize;
  margin-top: -5px;
  margin-right: -5px;
  top: 0;
  right: 0;
}

.ReactCrop .ord-ne:after {
  top: 0;
  right: 0;
}

.ReactCrop .ord-e {
  cursor: e-resize;
  margin-top: -5px;
  margin-right: -5px;
  top: 50%;
  right: 0;
}

.ReactCrop .ord-e:after {
  right: 0;
}

.ReactCrop .ord-se {
  cursor: se-resize;
  margin-bottom: -5px;
  margin-right: -5px;
  bottom: 0;
  right: 0;
}

.ReactCrop .ord-se:after {
  bottom: 0;
  right: 0;
}

.ReactCrop .ord-s {
  cursor: s-resize;
  margin-bottom: -5px;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.ReactCrop .ord-s:after {
  bottom: 0;
}

.ReactCrop .ord-sw {
  cursor: sw-resize;
  margin-bottom: -5px;
  margin-left: -5px;
  bottom: 0;
  left: 0;
}

.ReactCrop .ord-sw:after {
  bottom: 0;
  left: 0;
}

.ReactCrop .ord-w {
  cursor: w-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 50%;
  left: 0;
}

.ReactCrop .ord-w:after {
  left: 0;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}

.ReactCrop__drag-bar {
  position: absolute;
}

.ReactCrop__drag-bar.ord-n {
  width: 100%;
  height: 6px;
  margin-top: -3px;
  top: 0;
  left: 0;
}

.ReactCrop__drag-bar.ord-e {
  width: 6px;
  height: 100%;
  margin-right: -3px;
  top: 0;
  right: 0;
}

.ReactCrop__drag-bar.ord-s {
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
  bottom: 0;
  left: 0;
}

.ReactCrop__drag-bar.ord-w {
  width: 6px;
  height: 100%;
  margin-left: -3px;
  top: 0;
  left: 0;
}

.ReactCrop--new-crop .ReactCrop__drag-bar, .ReactCrop--new-crop .ReactCrop__drag-handle, .ReactCrop--fixed-aspect .ReactCrop__drag-bar, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}

@media (pointer: coarse) {
  .ReactCrop .ord-n, .ReactCrop .ord-e, .ReactCrop .ord-s, .ReactCrop .ord-w {
    display: none;
  }

  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}

/*# sourceMappingURL=index.1e4d8506.css.map */
