.flipping-box {
    background-color: transparent;
    perspective: 1000px;
}

.flipping-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}

.flipping-box:hover .flipping-box-inner {
    transform: rotateY(180deg);
}

.flipping-box-front, .flipping-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flipping-box-back {
    transform: rotateY(180deg);
}